import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import localePl from '@angular/common/locales/pl';
import localeZh from '@angular/common/locales/zh';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPopperjsModule, NgxPopperjsPlacements } from 'ngx-popperjs';
import { NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthHttpInterceptor, ErrorInterceptor, LoaderInterceptor, MockHttpInterceptor } from './interceptor';
import { CoreRoutingModule } from './routing';
import { Locale, PermissionType, Timezone } from '@model';
import {
  ChartService,
  CookieService,
  DashboardService,
  DialogService,
  GlobalAlertService,
  GoogleService,
  I18nService,
  IconsService,
  LoaderService,
  LocalesService,
  LoginRedirectService,
  PermissionService,
  ProgramService,
  ResourceService,
  RouterService,
  SidenavService,
  SiteService,
  TimezonesService,
  TreeService,
} from '@service';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const initRouterCheck = (routerService: RouterService) => (): Promise<void> => routerService.initRouterCheck();

const initIcon = (icon: IconsService) => (): Promise<void> => icon.load();

const initTimezones = (timezone: TimezonesService) => (): Observable<Timezone[]> => timezone.loadTimezones();

const initLocales = (locale: LocalesService) => (): Observable<Locale[]> => locale.setLocales();

const initLoginRedirect = (loginRedirect: LoginRedirectService) => (): Promise<void> => loginRedirect.loadLoginPage();

const initPermissions = (permissionsService: PermissionService) => (): Observable<PermissionType> =>
  permissionsService.getPermissions().pipe(take(1));

const placement = NgxPopperjsPlacements.RIGHT;

@NgModule({
  imports: [
    CoreRoutingModule,
    NgxPopperjsModule.forRoot({ placement }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [CoreRoutingModule, TranslateModule],
  providers: [
    ChartService,
    CookieService,
    DashboardService,
    DialogService,
    GlobalAlertService,
    GoogleService,
    I18nService,
    IconsService,
    LoaderService,
    LocalesService,
    LoginRedirectService,
    ResourceService,
    RouterService,
    SidenavService,
    SiteService,
    TimezonesService,
    TreeService,
    PermissionService,
    ProgramService,
    {
      provide: APP_INITIALIZER,
      useFactory: initIcon,
      deps: [IconsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLocales,
      deps: [LocalesService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoginRedirect,
      deps: [LoginRedirectService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initPermissions,
      deps: [PermissionService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initRouterCheck,
      deps: [RouterService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initTimezones,
      deps: [TimezonesService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CoreModule {
  // Make sure CoreModule is imported only by one NgModule the BMDModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in BMDModule');
    }
  }
}
