import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { Crons, ProgramConfig, Site } from '@model';
import { CronService, ProgramService, SiteService } from '@service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SiteResolver implements Resolve<[Site, Crons, ProgramConfig]> {
  constructor(
    private siteService: SiteService,
    private programService: ProgramService,
    private cronService: CronService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[Site, Crons, ProgramConfig]> {
    const siteId = route.queryParamMap.get('siteId') || route.paramMap.get('siteId');
    const crons = this.cronService.getCrons(siteId);
    const site = this.siteService.getSite(siteId);
    const programConfig = this.programService.getProgramConfig();
    return forkJoin([of(site), crons, programConfig]).pipe(
      catchError(error => {
        console.log(`the error is `, error);
        return of(error);
      }),
    );
  }
}
