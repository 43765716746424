<div class="content">
  <header class="sticky-header">
    <div class="left-column">
      <div class="icon-header">
        <div class="icon-container">
          <mat-icon>edit</mat-icon>
        </div>
        <h1 id="editTitle" data-automation="editTitle">
          <ng-container>{{ site?.name }}</ng-container>
        </h1>
        <p class="x-desktop-only subtitle" data-automation="editSubtitle"></p>
      </div>
    </div>

    <div class="right-column flex"></div>
  </header>

  <div [ngSwitch]="mode" class="margin-page-top">
    <div class="site-tabs">
      <h3 [ngClass]="{
        'tab': true,
        'active': mode === 'details'
      }" (click)="changeMode(Modes.details)">
        {{ 'site.details' | translate}}
      </h3>
      <h3 [ngClass]="{
        'tab': true,
        'active': mode === 'configure'
      }" (click)="changeMode(Modes.configure)">
        {{ 'site.management' | translate}}
      </h3>
      <h3 [ngClass]="{
        'tab': true,
        'active': mode === 'events'
      }" (click)="changeMode(Modes.events)">
        {{ 'site.events' | translate}}
      </h3>
    </div>
    <bmd-site-home *ngSwitchCase="tabsEnum.details" [site]="site" [siteForm]="siteForm"></bmd-site-home>
    <bmd-site-configure *ngSwitchCase="tabsEnum.configure" [site]="site" [crons]="crons" [programConfig]="programConfig"></bmd-site-configure>
    <bmd-site-events *ngSwitchCase="tabsEnum.events" [site]="site"></bmd-site-events>
  </div>
</div>
