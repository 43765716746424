<ng-template #emptyTemplate>
  <div class="subsection-container">
    <span class="ml-3">{{ 'site.der.no_data' | translate}}</span>
  </div>
</ng-template>

<div *ngIf="site">
  <div class="details-row first-row">
    <div class="details-half-row site-device-name-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-device-name-label">{{ 'site.name' | translate }}:</span>
      <div id="site-device-name">
        <span data-cy="site-device-name">{{ site.name }}</span>
      </div>
    </div>
    <div class="details-half-row site-device-id-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-device-id">{{ 'site.id' | translate }}:</span>
      <div id="site-device-id">
        <span data-cy="site-device-id">{{ site.id }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class="details-half-row">
      <span class="text-light-color label-fixed-width"
            data-cy="site-timezone-label">{{ 'site.timezone' | translate }}:</span>
      <div id="site-timezone">
        <span data-cy="site-timezone">{{ site.timezone }}</span>
        <span data-cy="site-timezone">{{ site.timezone }}</span>
      </div>
    </div>
    <div class="details-half-row">
      <span class="text-light-color label-fixed-width"
            data-cy="site-address-label">{{ 'site.information.address' | translate }}:</span>
      <div id="site-address">
        <span data-cy="site-address">{{ site.settings.address }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class="details-half-row">
      <span class="text-light-color label-fixed-width"
            data-cy="site-dispatch-unit-generation-label">{{ 'site.config.dispatch_unit_generation' | translate }}:</span>
      <div id="site-dispatch-unit-generation">
        <span data-cy="site-dispatch-unit-generation">{{ site.config.dispatchUnitGeneration }}</span>
      </div>
    </div>
    <div class="details-half-row">
      <span class="text-light-color label-fixed-width"
            data-cy="site-dispatch-unit-load">{{ 'site.config.dispatch_unit_load' | translate }}:</span>
      <div id="site-dispatch-unit-load">
        <span data-cy="site-dispatch-unit-load">{{ site.config.dispatchUnitLoad }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class="details-half-row site-latitude-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-latitude-label">{{ 'site.information.latitude' | translate }}:</span>
      <div id="site-latitude">
        <span data-cy="site-latitude">{{ site.settings.latitude }}</span>
      </div>
    </div>
    <div class="details-half-row site-longitude-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-longitude-label">{{ 'site.information.longitude' | translate }}:</span>
      <div id="site-longitude">
        <span data-cy="site-longitude">{{ site.settings.longitude }}</span>
      </div>
    </div>
  </div>

    <div class="details-row">
        <div class="details-half-row site-resource-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-resource-label">{{ 'site.config.resource_node' | translate }}:</span>
            <div id="site-resource-node">
                <span data-cy="site-resource-node">{{ site.config.objectId }}</span>
            </div>
        </div>
        <div class="details-half-row site-market-participant-container">
      <span class="text-light-color label-fixed-width"
            data-cy="site-market-participant-label">{{ 'site.config.market_participant' | translate }}:</span>
            <div id="site-market-participant">
                <span data-cy="site-market-participant">{{ site.config.apxMarketParticipant }}</span>
            </div>
        </div>
    </div>


  <div class="details-row">
    <div class="details-half-row">
      <span class="text-light-color label-fixed-width"
            data-cy="site-itc-enabled">{{ 'site.config.itc_enabled' | translate }}:</span>
      <div id="site-itc-enabled">
        <span data-cy="site-itc-enbaled">{{ site.config.solarItc }}</span>
      </div>
    </div>
    <div class="details-half-row"></div>
  </div>
    <hr />
  <div class="details-row">
    <span class="text-light-color label-fixed-width details-half-row"
          data-cy="site-device-name-label">{{ 'site.config.bess_info' | translate }}:</span>
    <div id="site-bess-info" class="details-half-row">
      <div data-cy="site-bess-info" class="w-400 flex justify-between mb-2"><span class="text-light-color label-fixed-width">{{ 'site.config.max_charge_capacity_kw' | translate }}:</span> {{ site.config.maxChargePowerKW }}</div>
      <div data-cy="site-bess-info" class="w-400 flex justify-between mb-2"><span class="text-light-color label-fixed-width">{{ 'site.config.max_discharge_capacity_kw' | translate }}:</span> {{ site.config.maxDischargePowerKW }}</div>
        <div class="w-400 flex justify-between"><span class="text-light-color label-fixed-width">{{ 'site.config.operation_cost_mwh' | translate }}: </span><input type="number" [formControl]="operationCostPerMWh" /></div>
    </div>
  </div>
    <hr/>
    <div class="details-row">
        <div class="details-half-row">
        </div>
        <div class="details-half-row">
            <div class="w-400 flex justify-between mb-2" data-cy="reg-up-mw-label"><span class="text-light-color label-fixed-width">{{ 'site.config.max_regup_mw' | translate }}: </span><input type="number" data-cy="reg-up-mw" [formControl]="maxRegupMw" /></div>
            <div class="w-400 flex justify-between mb-2" data-cy="reg-down-mw-label"><span class="text-light-color label-fixed-width">{{ 'site.config.max_regdown_mw' | translate }}: </span><input type="number" data-cy="reg-down-mw" [formControl]="maxRegdownMw" /></div>
            <div class="w-400 flex justify-between mb-2" data-cy="rr-mw-label"><span class="text-light-color label-fixed-width">{{ 'site.config.max_rrs_mw' | translate }}: </span><input type="number" data-cy="rrs-mw" [formControl]="maxRrsMw" /></div>
            <div class="w-400 flex justify-between" data-cy="ecrs-mw-label"><span class="text-light-color label-fixed-width">{{ 'site.config.max_ecrs_mw' | translate }}: </span><input type="number" data-cy="ecrs-mw" [formControl]="maxEcrsMw" /></div>
        </div>
    </div>
    <hr />
    <div class="details-row">
        <div class="details-half-row">
        </div>
        <div class="details-half-row">
            <div class="flex w-400 justify-end">
                <button type="button" class="x-btn x-btn-primary btn-bottom" (click)="saveSite()" [disabled]="siteForm.invalid || !siteForm.dirty">Save</button> &nbsp;
            </div>
        </div>
    </div>
</div>
