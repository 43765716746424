<bmd-global-alert></bmd-global-alert>

<global-nav [navUrl]="navUrl"></global-nav>

<mat-sidenav-container (backdropClick)="sidenavOpen = false">
  <mat-sidenav
    #sidenav
    class="sidenav-container"
    fixedInViewport="true"
    fixedTopGap="60"
    [mode]="isLargeScreen() ? 'side' : 'over'"
    [opened]="sidenavOpen"
  >
    <div class="sidenav-header">
      <div class="flex justify-between items-center">
        <h1 class="page-title" data-cy="listTitle" [translate]="'APP.TITLE'"></h1>
        <button
          mat-icon-button
          color="accent"
          aria-label="Menu"
          class="toggle-sidenav flex justify-center items-center"
          data-cy="toggle-sidenav"
          (click)="sidenavOpen = !sidenavOpen"
        >
          <mat-icon>double_arrow</mat-icon>
        </button>
      </div>
    </div>
    <div class="sidenav-component-container" data-cy="tree-container">
      <bmd-tree></bmd-tree>
    </div>
  </mat-sidenav>
  <mat-sidenav-content data-cy="sidenav-content" [class.sidenav-content-stretch]="!sidenavOpen">
    <div data-cy="scrollable-container" class="full overflow-auto">
      <div data-cy="main-container" class="main-container">
        <bmd-loader></bmd-loader>
        <bmd-component></bmd-component>
        <global-footer></global-footer>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
